import { Alert, Col, Form, Input, Row, Select, Spin, Switch, message } from "antd";
import React, { useState } from "react";
import TextEditor from "../Core/ReactTinymce/ReactTinymce";

//import "antd/dist/antd.css";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useGetBlogListQuery } from "../../redux/slice/blog/blogApiSlice";
import { useGetCommentListQuery } from "../../redux/slice/comment/commentApiSlice";
import { cleanFormValues } from "../../utils/cleanValues";
import DashboardActionBar from "../DashboardActionBar";

const { Option } = Select;

const CommentForm = ({
  selectedForm,
  createEntity,
  updateEntityInfo,
  isAddForm,
  isUpdateForm,
  params,
  getEntityById,
  setUpdatedRecentlyToFalse,
  data,
  // getBlogList,
  // blogListData,
  // getCommentList,
  // commentListData,
}) => {
  const navigate = useNavigate();
  const [comment, setComment] = useState(isUpdateForm && data.dataById?.comment);
  const [avatar, setAvatar] = useState(
    isUpdateForm ? data.dataById && data.dataById.avatar && data.dataById.avatar.full_path : null
  );
  const [image_crop_open, setImageCropOpen] = useState(false);

  const [form] = Form.useForm();
  const { setFieldsValue } = form;

  const {
    data: blogListData,
    isLoding: blogListLoading,
    isError: blogLoadingError,
  } = useGetBlogListQuery();

  const {
    data: commentListData,
    isLoding: commentListLoading,
    isError: comentLoadingError,
  } = useGetCommentListQuery();

  if (blogLoadingError) {
    message.error("Error on loading blog list");
  }
  if (comentLoadingError) {
    message.error("Error on loading comment list");
  }

  const loading = selectedForm.loading || blogListLoading || commentListLoading || data.loading;

  const blogListOptions = blogListData?.map((itm) => {
    return {
      value: itm.id,
      label: itm.title,
    };
  });

  let commentListOptions = commentListData?.map((itm) => {
    return {
      value: itm.id,
      label: itm.commentor_name,
    };
  });

  if (isUpdateForm) {
    setFieldsValue({
      blog_id: data.dataById?.blog_id,
      parent_id: data.dataById?.parent_id,
      commentor_name: data.dataById?.commentor_name,
      commentor_email: data.dataById?.commentor_email,
      commentor_address: data.dataById?.commentor_address,
      commentor_contact: data.dataById?.commentor_contact,
      comment: data.dataById?.comment,
      approval_status: data.dataById?.approval_status === 1 ? true : false,
      // avatar: data.dataById?.avatar?.thumb_path,
      // avatar_alt: data.dataById?.avatar?.avatar_alt,
      // avatar_caption: data.dataById?.avatar?.avatar_caption
    });
  }

  const userAction = (e, action) => {
    e.preventDefault();

    form.validateFields().then((values) => {
      let cleanedValues = cleanFormValues(values);

      cleanedValues.approval_status = cleanedValues.approval_status ? 1 : 0;
      if (action === "add") {
        if (avatar) {
          axios.get(avatar, { responseType: "blob" }).then((response) => {
            createEntity({
              ...cleanedValues,
              avatar: response.data,
            });
          });
        } else {
          createEntity({
            ...cleanedValues,
          });
        }
      } else if (action === "addandexit") {
        if (avatar) {
          axios.get(avatar, { responseType: "blob" }).then((response) => {
            createEntity({
              ...cleanedValues,
              avatar: response.data,
            })
              .unwrap()
              .then(() => navigate("/blog/comments"))
              .catch((error) => message.error("error on creation: ", error));
          });
        } else {
          createEntity({
            ...cleanedValues,
          })
            .unwrap()
            .then(() => navigate("/blog/comments"))
            .catch((error) => message.error("error on creation: ", error));
        }
      } else if (action === "update") {
        if (avatar) {
          axios.get(avatar, { responseType: "blob" }).then((response) => {
            updateEntityInfo({
              formData: {
                ...cleanedValues,
                avatar: response.data,
              },
              id: params.id,
            });
          });
        } else {
          updateEntityInfo({
            formData: {
              ...cleanedValues,
            },
            id: params.id,
          });
        }
      }
    });
  };

  const addNewEntry = (e) => {
    userAction(e, "add");
  };

  const addNewEntryAndExistToList = (e) => {
    userAction(e, "addandexit");
  };

  const updateExistingEntry = (e) => {
    userAction(e, "update");
  };

  const clearForm = () => {
    form.resetFields();
    setComment("");
    setAvatar("");
  };

  const actionBarOptions = {
    titleToAdd: "Add Comment",
    titleToUpdate: "Update Comment",
    taskCompleteMessageDisplayTime: 2000,
    addButtonLabel: "Save & Add New",
    updateButtonLabel: "Update Comment",
    taskCompleteAddMessage: "Comment is added!",
    taskCompleteUpdateMessage: "Comment is updated!",
    discardLink: "blog/comments",
    addNewEntry,
    updateExistingEntry,
    loading,
    isAddForm,
    isUpdateForm,
    selectedForm,
    clearForm,
    setUpdatedRecentlyToFalse,
    addNewEntryAndExistToList,
  };

  return (
    <>
      <Form
        form={form}
        size="large"
        layout="vertical"
        className="custom-form"
        wrapperCol={{
          flex: 1,
        }}
      >
        <DashboardActionBar {...{ actionBarOptions }} />

        {selectedForm?.error?.errors && (
          <Alert
            className="custom-alert"
            message="Error"
            showIcon
            description={[
              <ul>
                {Object.values(selectedForm.error.errors).map((itm, idx) => {
                  return <li key={idx}>{itm}</li>;
                })}
              </ul>,
            ]}
            type="error"
            closable
          />
        )}
        <Spin spinning={loading} delay={500}>
          <div className="common-module mt-3 bg-white">
            <Row gutter={{ xs: 8, sm: 16, md: 24 }}>
              <Col className="gutter-row" xs={24} sm={12} xl={8}>
                <Form.Item
                  label="Name"
                  name="commentor_name"
                  //title="Auto Generate from Url Title"
                  rules={[
                    {
                      required: true,
                      message: "Name is required",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col className="gutter-row" xs={24} sm={12} xl={8}>
                <Form.Item
                  label="Email"
                  name="commentor_email"
                  //title="Auto Generate from Url Title"
                  rules={[
                    {
                      required: true,
                      message: "Email is required",
                    },
                  ]}
                >
                  <Input type="email" />
                </Form.Item>
              </Col>

              <Col className="gutter-row" xs={24} sm={12} xl={8}>
                <Form.Item label="Address" name="commentor_address">
                  <Input />
                </Form.Item>
              </Col>

              <Col className="gutter-row" xs={24} sm={12} xl={8}>
                <Form.Item label="Phone" name="commentor_contact">
                  <Input type="tel" />
                </Form.Item>
              </Col>

              <Col className="gutter-row" xs={24} sm={12} xl={8}>
                <Form.Item
                  label="Select a Blog"
                  name="blog_id"
                  rules={[
                    {
                      required: true,
                      message: "Blog is required",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    optionFilterProp="children"
                    filterOption={(input, option) =>
                      (option?.label ?? "").toLowerCase().includes(input)
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? "")
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? "").toLowerCase())
                    }
                    options={blogListOptions}
                  ></Select>
                </Form.Item>
              </Col>

              <Col className="gutter-row" xs={24} sm={12} xl={8}>
                <Form.Item label="Parent" name="parent_id">
                  <Select
                    showSearch
                    optionFilterProp="children"
                    optionLabelProp="label"
                    filterOption={(input, option) =>
                      (option?.label ?? "").toLowerCase().includes(input)
                    }
                    filterSort={(optionA, optionB) =>
                      (optionA?.label ?? "")
                        .toLowerCase()
                        .localeCompare((optionB?.label ?? "").toLowerCase())
                    }
                  >
                    <Option value={0} label="No parent">
                      No Parent
                    </Option>
                    {commentListOptions?.map((itm) => {
                      return (
                        <Option value={itm.value} label={itm.label}>
                          {itm.label}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>

              <Col className="gutter-row" xs={24} sm={12} xl={16}>
                <div className="switch-group">
                  <Form.Item label="Approval Status" valuePropName="checked" name="approval_status">
                    <Switch checkedChildren="Publish" unCheckedChildren="Unpublish" />
                  </Form.Item>
                </div>
              </Col>
            </Row>
          </div>
          <div className="common-module pt-0">
            <Row gutter={{ xs: 8, sm: 16, md: 24 }}>
              <Col className="gutter-row" xs={24} xl={24}>
                <Form.Item label="Comment" name="comment">
                  <TextEditor
                    value={comment}
                    initialValue={isUpdateForm && comment}
                    onEditorChange={(e) => {
                      setComment(e.target.value);
                    }}
                  />
                </Form.Item>
              </Col>

              {/* <Col className="gutter-row" xs={24} xl={10}>
              <div className="card bg-white mb-4 mb-xl-0">
                <div className="card-body">
                  <Form.Item>
                    <ImageField
                      title="Avatar"
                      image={
                        avatar ||
                        (isUpdateForm && data.dataById?.avatar?.full_path)
                      }
                      height={150}
                      width={150}
                      setImage={(image_data) => {
                        setAvatar(image_data);
                        setImageCropOpen(true);
                      }}
                    />
                    {avatar && image_crop_open && (
                      <ImageCrop
                        image={avatar}
                        height={150}
                        width={150}
                        setCroppedImage={setAvatar}
                        setImageCropOpen={setImageCropOpen}
                      />
                    )}
                  </Form.Item>
                  <Form.Item
                    style={{ marginTop: "-20px" }}
                    label="Alt Text"
                    name="avatar_alt"
                    rules={[{ min: 5, max: 50, message: "Invalid Alt Text" }]}
                  >
                    <Input />
                  </Form.Item>
                  <Form.Item
                    label="Caption"
                    name="avatar_caption"
                    rules={[{ min: 5, max: 50, message: "Invalid caption" }]}
                  >
                    <Input />
                  </Form.Item>
                </div>
              </div>
            </Col> */}
            </Row>
          </div>
        </Spin>
      </Form>
    </>
  );
};

export default CommentForm;
