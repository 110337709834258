import { Alert, Col, Form, Input, InputNumber, Row, Select, Spin, Switch, message } from "antd";
import TextArea from "antd/lib/input/TextArea";
import axios from "axios";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import slugify from "react-slugify";
import { useGetCategoryListByTypeKeyQuery } from "../../redux/slice/category/categoryApiSlice";
import { cleanFormValues } from "../../utils/cleanValues";
import ImageField from "../Core/ImageField";
import TextEditor from "../Core/ReactTinymce/ReactTinymce";
import DashboardActionBar from "../DashboardActionBar";
import ImageCrop from "../ImageCrop";

const { Option } = Select;

const TeamForm = ({
  selectedForm,
  createEntity,
  updateEntityInfo,
  isAddForm,
  isUpdateForm,
  params,
  getEntityById,
  data,
}) => {
  const [description, setDescription] = useState(isUpdateForm && data.dataById?.description);
  const [avatar, setAvatar] = useState(isUpdateForm ? data?.dataById?.avatar?.full_path : "null");

  const [image_crop_open, setImageCropOpen] = useState(false);
  const navigate = useNavigate();

  const [form] = Form.useForm();
  const { validateFields, setFieldsValue, getFieldValue } = form;

  let category_type_key = "team";
  const {
    data: categoriesByType,
    isLoading: categoryListLoading,
    isError: categoryListError,
  } = useGetCategoryListByTypeKeyQuery({
    type_key: category_type_key,
  });

  const loading = selectedForm.loading || categoryListLoading || data.loading;

  if (categoryListError) {
    message.error("Error on loading category list");
  }

  let categoriesOptions = categoriesByType?.map((category) => {
    const { id, title, children } = category;
    return {
      value: id,
      label: title,
      ...(children && {
        children: children?.map((child) => {
          const { id, title, children } = child;
          return {
            value: id,
            label: title,
            ...(children && {
              children: children?.map((gChild) => {
                const { id, title } = gChild;
                return {
                  value: id,
                  label: title,
                };
              }),
            }),
          };
        }),
      }),
    };
  });

  const countryList = {
    AF: "Afghanistan",
    AL: "Albania",
    DZ: "Algeria",
    AS: "American Samoa",
    AD: "Andorra",
    AO: "Angola",
    AI: "Anguilla",
    AQ: "Antarctica",
    AG: "Antigua and Barbuda",
    AR: "Argentina",
    AM: "Armenia",
    AW: "Aruba",
    AU: "Australia",
    AT: "Austria",
    AZ: "Azerbaijan",
    BS: "Bahamas (the)",
    BH: "Bahrain",
    BD: "Bangladesh",
    BB: "Barbados",
    BY: "Belarus",
    BE: "Belgium",
    BZ: "Belize",
    BJ: "Benin",
    BM: "Bermuda",
    BT: "Bhutan",
    BO: "Bolivia (Plurinational State of)",
    BQ: "Bonaire, Sint Eustatius and Saba",
    BA: "Bosnia and Herzegovina",
    BW: "Botswana",
    BV: "Bouvet Island",
    BR: "Brazil",
    IO: "British Indian Ocean Territory (the)",
    BN: "Brunei Darussalam",
    BG: "Bulgaria",
    BF: "Burkina Faso",
    BI: "Burundi",
    CV: "Cabo Verde",
    KH: "Cambodia",
    CM: "Cameroon",
    CA: "Canada",
    KY: "Cayman Islands (the)",
    CF: "Central African Republic (the)",
    TD: "Chad",
    CL: "Chile",
    CN: "China",
    CX: "Christmas Island",
    CC: "Cocos (Keeling) Islands (the)",
    CO: "Colombia",
    KM: "Comoros (the)",
    CD: "Congo (the Democratic Republic of the)",
    CG: "Congo (the)",
    CK: "Cook Islands (the)",
    CR: "Costa Rica",
    HR: "Croatia",
    CU: "Cuba",
    CW: "Curaçao",
    CY: "Cyprus",
    CZ: "Czechia",
    CI: "Côte d'Ivoire",
    DK: "Denmark",
    DJ: "Djibouti",
    DM: "Dominica",
    DO: "Dominican Republic (the)",
    EC: "Ecuador",
    EG: "Egypt",
    SV: "El Salvador",
    GQ: "Equatorial Guinea",
    ER: "Eritrea",
    EE: "Estonia",
    SZ: "Eswatini",
    ET: "Ethiopia",
    FK: "Falkland Islands (the) [Malvinas]",
    FO: "Faroe Islands (the)",
    FJ: "Fiji",
    FI: "Finland",
    FR: "France",
    GF: "French Guiana",
    PF: "French Polynesia",
    TF: "French Southern Territories (the)",
    GA: "Gabon",
    GM: "Gambia (the)",
    GE: "Georgia",
    DE: "Germany",
    GH: "Ghana",
    GI: "Gibraltar",
    GR: "Greece",
    GL: "Greenland",
    GD: "Grenada",
    GP: "Guadeloupe",
    GU: "Guam",
    GT: "Guatemala",
    GG: "Guernsey",
    GN: "Guinea",
    GW: "Guinea-Bissau",
    GY: "Guyana",
    HT: "Haiti",
    HM: "Heard Island and McDonald Islands",
    VA: "Holy See (the)",
    HN: "Honduras",
    HK: "Hong Kong",
    HU: "Hungary",
    IS: "Iceland",
    IN: "India",
    ID: "Indonesia",
    IR: "Iran (Islamic Republic of)",
    IQ: "Iraq",
    IE: "Ireland",
    IM: "Isle of Man",
    IL: "Israel",
    IT: "Italy",
    JM: "Jamaica",
    JP: "Japan",
    JE: "Jersey",
    JO: "Jordan",
    KZ: "Kazakhstan",
    KE: "Kenya",
    KI: "Kiribati",
    KP: "Korea (the Democratic People's Republic of)",
    KR: "Korea (the Republic of)",
    KW: "Kuwait",
    KG: "Kyrgyzstan",
    LA: "Lao People's Democratic Republic (the)",
    LV: "Latvia",
    LB: "Lebanon",
    LS: "Lesotho",
    LR: "Liberia",
    LY: "Libya",
    LI: "Liechtenstein",
    LT: "Lithuania",
    LU: "Luxembourg",
    MO: "Macao",
    MG: "Madagascar",
    MW: "Malawi",
    MY: "Malaysia",
    MV: "Maldives",
    ML: "Mali",
    MT: "Malta",
    MH: "Marshall Islands (the)",
    MQ: "Martinique",
    MR: "Mauritania",
    MU: "Mauritius",
    YT: "Mayotte",
    MX: "Mexico",
    FM: "Micronesia (Federated States of)",
    MD: "Moldova (the Republic of)",
    MC: "Monaco",
    MN: "Mongolia",
    ME: "Montenegro",
    MS: "Montserrat",
    MA: "Morocco",
    MZ: "Mozambique",
    MM: "Myanmar",
    NA: "Namibia",
    NR: "Nauru",
    NP: "Nepal",
    NL: "Netherlands (the)",
    NC: "New Caledonia",
    NZ: "New Zealand",
    NI: "Nicaragua",
    NE: "Niger (the)",
    NG: "Nigeria",
    NU: "Niue",
    NF: "Norfolk Island",
    MP: "Northern Mariana Islands (the)",
    NO: "Norway",
    OM: "Oman",
    PK: "Pakistan",
    PW: "Palau",
    PS: "Palestine, State of",
    PA: "Panama",
    PG: "Papua New Guinea",
    PY: "Paraguay",
    PE: "Peru",
    PH: "Philippines (the)",
    PN: "Pitcairn",
    PL: "Poland",
    PT: "Portugal",
    PR: "Puerto Rico",
    QA: "Qatar",
    MK: "Republic of North Macedonia",
    RO: "Romania",
    RU: "Russian Federation (the)",
    RW: "Rwanda",
    RE: "Réunion",
    BL: "Saint Barthélemy",
    SH: "Saint Helena, Ascension and Tristan da Cunha",
    KN: "Saint Kitts and Nevis",
    LC: "Saint Lucia",
    MF: "Saint Martin (French part)",
    PM: "Saint Pierre and Miquelon",
    VC: "Saint Vincent and the Grenadines",
    WS: "Samoa",
    SM: "San Marino",
    ST: "Sao Tome and Principe",
    SA: "Saudi Arabia",
    SN: "Senegal",
    RS: "Serbia",
    SC: "Seychelles",
    SL: "Sierra Leone",
    SG: "Singapore",
    SX: "Sint Maarten (Dutch part)",
    SK: "Slovakia",
    SI: "Slovenia",
    SB: "Solomon Islands",
    SO: "Somalia",
    ZA: "South Africa",
    GS: "South Georgia and the South Sandwich Islands",
    SS: "South Sudan",
    ES: "Spain",
    LK: "Sri Lanka",
    SD: "Sudan (the)",
    SR: "Suriname",
    SJ: "Svalbard and Jan Mayen",
    SE: "Sweden",
    CH: "Switzerland",
    SY: "Syrian Arab Republic",
    TW: "Taiwan",
    TJ: "Tajikistan",
    TZ: "Tanzania, United Republic of",
    TH: "Thailand",
    TL: "Timor-Leste",
    TG: "Togo",
    TK: "Tokelau",
    TO: "Tonga",
    TT: "Trinidad and Tobago",
    TN: "Tunisia",
    TR: "Turkey",
    TM: "Turkmenistan",
    TC: "Turks and Caicos Islands (the)",
    TV: "Tuvalu",
    UG: "Uganda",
    UA: "Ukraine",
    AE: "United Arab Emirates (the)",
    GB: "United Kingdom of Great Britain and Northern Ireland (the)",
    UM: "United States Minor Outlying Islands (the)",
    US: "United States of America (the)",
    UY: "Uruguay",
    UZ: "Uzbekistan",
    VU: "Vanuatu",
    VE: "Venezuela (Bolivarian Republic of)",
    VN: "Viet Nam",
    VG: "Virgin Islands (British)",
    VI: "Virgin Islands (U.S.)",
    WF: "Wallis and Futuna",
    EH: "Western Sahara",
    YE: "Yemen",
    ZM: "Zambia",
    ZW: "Zimbabwe",
    AX: "Åland Islands",
  };

  const countries = Object.entries(countryList).map(([key, value], i) => {
    return (
      <Option key={i} value={key}>
        {value}
      </Option>
    );
  });

  if (isUpdateForm) {
    setFieldsValue({
      salutation: data.dataById.salutation,
      full_name: data.dataById.full_name,
      url_title: data.dataById.urlinfo?.url_title,
      country_code: data.dataById.country_code,
      country_name: data.dataById.country_name,
      slug: data.dataById.urlinfo?.url_slug,
      categories: data.dataById?.categories?.map((a) => a.id),
      position: data.dataById.position,
      position_order: data.dataById.position_order,
      member_address: data.dataById.member_address,
      member_phone: data.dataById.member_phone,
      member_email: data.dataById.member_email,
      member_facebook: data.dataById.member_facebook,
      member_twitter: data.dataById.member_twitter,
      member_instagram: data.dataById.member_instagram,
      status: data.dataById.status === 1 ? true : false,
      is_featured: data.dataById.is_featured === 1 ? true : false,
      member_website: data.dataById.member_website,
      quote_abstract: data.dataById.quote_abstract,
      quote: data.dataById.quote,
      description: data.dataById.description,
      meta_title: data.dataById.meta?.meta_title,
      meta_keywords: data.dataById.meta?.meta_keywords,
      meta_description: data.dataById.meta?.meta_description,
      avatar: data.dataById.avatar?.full_path,
      avatar_alt: data.dataById.avatar?.alt_text,
      avatar_caption: data.dataById.avatar?.caption,
    });
  }

  const handleFormSubmit = async (values, action) => {
    const cleanedValues = cleanFormValues(values);
    const formattedValues = {
      ...cleanedValues,
      status: cleanedValues.status ? 1 : 0,
      is_featured: cleanedValues.is_featured ? 1 : 0,
      country_name: countryList[`${cleanedValues.country_code}`],
    };
    if (avatar) {
      try {
        const response = await axios.get(avatar, { responseType: "blob" });
        formattedValues.avatar = response.data;
      } catch (error) {
        console.error("Error fetching the featured image:", error);
      }
    }
    if (action === "add") {
      createEntity(formattedValues)
        .unwrap()
        .then(() => clearForm());
    } else if (action === "addandexit") {
      createEntity(formattedValues)
        .unwrap()
        .then(() => navigate("/teams"))
        .catch((error) => message.error("Error creating Team:", error));
    } else if (action === "update") {
      updateEntityInfo({
        formData: formattedValues,
        id: params.id,
      });
    }
  };

  const userAction = (e, action) => {
    e.preventDefault();
    form
      .validateFields()
      .then((values) => {
        handleFormSubmit(values, action);
      })
      .catch((error) => {
        console.error("Validation failed:", error);
      });
  };

  const addNewEntry = (e) => {
    userAction(e, "add");
  };

  const addNewEntryAndExistToList = (e) => {
    userAction(e, "addandexit");
  };

  const updateExistingEntry = (e) => {
    userAction(e, "update");
  };

  const clearForm = () => {
    form.resetFields();
    setDescription("");
    setAvatar("");
  };

  const actionBarOptions = {
    titleToAdd: "Add Member",
    titleToUpdate: "Update Member",
    taskCompleteMessageDisplayTime: 2000,
    addButtonLabel: "Save & Add New",
    updateButtonLabel: "Update Member",
    taskCompleteAddMessage: "Member is added!",
    taskCompleteUpdateMessage: "Member is updated!",
    pageUrl: isUpdateForm && "team/" + data.dataById.urlinfo?.url_slug,
    discardLink: "teams",
    addNewEntry,
    updateExistingEntry,
    loading,
    isAddForm,
    isUpdateForm,
    selectedForm,
    clearForm,
    addNewEntryAndExistToList,
  };

  return (
    <>
      <Form
        form={form}
        size="large"
        layout="vertical"
        className="custom-form"
        wrapperCol={{
          flex: 1,
        }}
      >
        <DashboardActionBar {...{ actionBarOptions }} />

        {selectedForm?.error?.errors && (
          <Alert
            className="custom-alert"
            message="Error"
            showIcon
            description={[
              <ul>
                {Object.values(selectedForm?.error).map((itm, idx) => {
                  return <li key={idx}>{itm}</li>;
                })}
              </ul>,
            ]}
            type="error"
            closable
          />
        )}
        <Spin spinning={loading} delay={500}>
          <div className="common-module mt-3 bg-white">
            <Row gutter={{ xs: 8, sm: 16, md: 24 }}>
              <Col className="gutter-row" xs={24} sm={12} xl={8}>
                <Row gutter={{ xs: 2.5, sm: 2.5, md: 2.5 }}>
                  <Col className="gutter-row" xs={6}>
                    <Form.Item
                      label="Full Name"
                      name="salutation"
                      rules={[
                        {
                          required: true,
                          message: "Salutation is required",
                        },
                      ]}
                    >
                      <Select>
                        <Option value="Mr.">Mr.</Option>
                        <Option value="Ms.">Ms.</Option>
                        <Option value="Mrs.">Mrs.</Option>
                        <Option value="Miss.">Miss.</Option>
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col className="gutter-row" xs={18}>
                    <Form.Item
                      label="&nbsp;"
                      name="full_name"
                      className="noStar"
                      rules={[
                        {
                          required: true,
                          message: "Full name is required",
                        },
                        { min: 5, max: 255, message: "Invalid Name" },
                      ]}
                    >
                      <Input
                      // onChange={(e) =>
                      //   form.setFieldsValue({
                      //     slug: slugify(e.target.value),
                      //     url_title: e.target.value,
                      //   })
                      // }
                      />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
              <Col className="gutter-row" xs={24} sm={12} xl={8}>
                <Form.Item
                  label="Title"
                  name="url_title"
                  rules={[
                    {
                      required: true,
                      message: "Url title is required",
                    },
                    { min: 5, max: 255, message: "Invalid Url title" },
                  ]}
                >
                  <Input
                    onChange={(e) =>
                      form.setFieldsValue({
                        slug: slugify(e.target.value),
                      })
                    }
                  />
                </Form.Item>
              </Col>
              <Col className="gutter-row" xs={24} sm={12} xl={8}>
                <Form.Item
                  label={
                    <span className="ant-form-item-optional">Auto Generate from Url Title</span>
                  }
                  name="slug"
                  title="Auto Generate from Url Title"
                  rules={[
                    {
                      required: true,
                      message: "Slug is required",
                    },
                  ]}
                >
                  <Input readOnly />
                </Form.Item>
              </Col>

              <Col className="gutter-row" xs={24} sm={12} xl={8}>
                <Row gutter={{ xs: 2.5, sm: 2.5, md: 2.5 }}>
                  <Col className="gutter-row" xs={10}>
                    <Form.Item
                      label="Address"
                      name="country_code"
                      rules={[
                        {
                          required: true,
                          message: "Select your country",
                        },
                      ]}
                    >
                      <Select placeholder="Country" showSearch>
                        {countries}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col className="gutter-row" xs={14}>
                    <Form.Item
                      label="&nbsp;"
                      name="member_address"
                      className="noStar"
                      rules={[
                        {
                          required: true,
                          message: "Address is required",
                        },
                      ]}
                    >
                      <Input placeholder="city, state, zip" />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>

              <Col className="gutter-row" xs={24} sm={12} xl={8}>
                <Row gutter={{ xs: 2.5, sm: 2.5, md: 2.5 }}>
                  <Col className="gutter-row" xs={18}>
                    <Form.Item
                      label="Designation"
                      name="position"
                      rules={[
                        {
                          required: true,
                          message: "Designation is required!",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col className="gutter-row" xs={6}>
                    <Form.Item
                      label="&nbsp;"
                      name="position_order"
                      className="noStar"
                      rules={[
                        {
                          required: true,
                          message: "Position order is required!",
                        },
                      ]}
                    >
                      <InputNumber />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>

              <Col className="gutter-row" xs={24} sm={12} xl={8}>
                <Form.Item
                  label="Department"
                  name="categories"
                  rules={[
                    {
                      required: true,
                      message: "Select your department!",
                    },
                  ]}
                >
                  <Select
                    mode="multiple"
                    filterOption={(inputValue, option) =>
                      option.label?.toLowerCase().includes(inputValue?.toLowerCase())
                    }
                  >
                    {categoriesOptions?.map((itm, idx) => {
                      return (
                        <Option value={itm.value} key={idx} label={itm.label}>
                          {itm.label}
                        </Option>
                      );
                    })}
                  </Select>
                </Form.Item>
              </Col>
              <Col className="gutter-row" xs={24} sm={12} xl={16}>
                <div className="switch-group">
                  <Form.Item label="Status" valuePropName="checked" name="status">
                    <Switch checkedChildren="Publish" unCheckedChildren="Unpublish" />
                  </Form.Item>
                  <Form.Item label="Is Featured?" valuePropName="checked" name="is_featured">
                    <Switch checkedChildren="Yes" unCheckedChildren="No" />
                  </Form.Item>
                </div>
              </Col>
            </Row>
          </div>
          <div className="common-module pt-0">
            <Row gutter={{ xs: 8, sm: 16, md: 24 }}>
              <Col className="gutter-row" xs={24} xl={16}>
                <Form.Item label="Description" name="description">
                  <TextEditor />
                </Form.Item>
              </Col>
              <Col className="gutter-row" xs={24} xl={8}>
                <label className="form-label">Meta</label>
                <div className="card bg-white mb-4 mb-xl-0">
                  <div className="card-body">
                    <Form.Item
                      label="Meta Title"
                      name="meta_title"
                      rules={[
                        {
                          required: true,
                          message: "Meta title is required",
                        },
                        { min: 5, max: 255, message: "Invalid meta title" },
                      ]}
                    >
                      <Input />
                    </Form.Item>

                    <Form.Item
                      label="Meta Keywords"
                      name="meta_keywords"
                      rules={[
                        {
                          min: 5,
                          max: 255,
                          message: "Invalid meta keywords",
                        },
                      ]}
                    >
                      <Input />
                    </Form.Item>

                    <Form.Item className="mb-0" label="Meta Description" name="meta_description">
                      <TextArea rows={8} />
                    </Form.Item>
                  </div>
                </div>
              </Col>

              <Col className="gutter-row" xs={24} xl={10}>
                <div className="card bg-white mb-4 mb-xl-0">
                  <div className="card-body">
                    <Form.Item>
                      <ImageField
                        title="Avatar"
                        image={avatar || (isUpdateForm && data.dataById?.avatar?.full_path)}
                        height={250}
                        width={250}
                        setImage={(image_data) => {
                          setAvatar(image_data);
                          setImageCropOpen(true);
                        }}
                      />
                      {avatar && image_crop_open && (
                        <ImageCrop
                          image={avatar}
                          height={250}
                          width={250}
                          setCroppedImage={setAvatar}
                          setImageCropOpen={setImageCropOpen}
                        />
                      )}
                    </Form.Item>
                    <Form.Item
                      style={{ marginTop: "-20px" }}
                      label="Alt Text"
                      name="avatar_alt"
                      rules={[{ min: 5, max: 50, message: "Invalid Alt Text" }]}
                    >
                      <Input />
                    </Form.Item>
                    <Form.Item
                      label="Caption"
                      name="avatar_caption"
                      rules={[{ min: 5, max: 50, message: "Invalid caption" }]}
                    >
                      <Input />
                    </Form.Item>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Spin>
      </Form>
    </>
  );
};

export default TeamForm;
