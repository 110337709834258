import { Alert, Col, Form, Input, Row, Select, Spin, Switch } from "antd";
import TextArea from "antd/lib/input/TextArea";
import React from "react";
import { useNavigate } from "react-router-dom";
import { cleanFormValues } from "../../utils/cleanValues";
import DashboardActionBar from "../DashboardActionBar";

const { Option } = Select;

const SettingForm = ({
  selectedForm,
  createEntity,
  updateEntityInfo,
  isAddForm,
  isUpdateForm,
  params,
  getEntityById,
  data,
}) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const { setFieldsValue } = form;
  const loading = selectedForm.loading || data.loading;

  const settingDataType = [
    { label: "String", value: "string" },
    { label: "Text", value: "text" },
    { label: "Integer", value: "integer" },
    { label: "Decimal", value: "decimal" },
    { label: "File", value: "file" },
    { label: "Image", value: "image" },
    { label: "Date", value: "date" },
  ];

  const settingCategories = [
    { label: "Company", value: "company" },
    { label: "Images", value: "images" },
    { label: "Advanced Settings", value: "advanced" },
  ];

  const userAction = (e, action) => {
    e.preventDefault();
    form.validateFields().then((values) => {
      const cleanedValues = cleanFormValues(values);

      cleanedValues.setting_status = cleanedValues.setting_status ? 1 : 0;
      cleanedValues.setting_protection = cleanedValues.setting_protection ? 1 : 0;
      if (action === "add") {
        createEntity({
          ...cleanedValues,
        });
      } else if (action === "addandexit") {
        createEntity({
          ...cleanedValues,
        })
          .unwrap()
          .then(navigate("/settings"));
      } else if (action === "update") {
        updateEntityInfo({
          formData: {
            ...cleanedValues,
          },
          id: params.id,
        });
      }
    });
  };

  const addNewEntry = (e) => {
    userAction(e, "add");
  };

  const addNewEntryAndExistToList = (e) => {
    userAction(e, "addandexit");
  };

  const updateExistingEntry = (e) => {
    userAction(e, "update");
  };

  const clearForm = () => {
    form.resetFields();
  };

  if (isUpdateForm) {
    setFieldsValue({
      setting_category: data.dataById?.key_group,
      setting_key: data.dataById?.global_key,
      setting_label: data.dataById?.key_label,
      setting_protection: data.dataById?.is_protected === 1 ? true : false,
      setting_status: data.dataById?.status === 1 ? true : false,
      setting_data_type: data.dataById?.value_type,
      setting_description: data.dataById?.description,
    });
  }

  const actionBarOptions = {
    titleToAdd: "Add Setting",
    titleToUpdate: "Update Setting",
    taskCompleteMessageDisplayTime: 2000,
    addButtonLabel: "Save & New",
    updateButtonLabel: "Update Setting",
    taskCompleteAddMessage: "Setting is added!",
    taskCompleteUpdateMessage: "Setting is updated!",
    discardLink: "settings",
    addNewEntry,
    updateExistingEntry,
    loading,
    isAddForm,
    isUpdateForm,
    selectedForm,
    clearForm,
    addNewEntryAndExistToList,
  };

  return (
    <>
      <Form
        form={form}
        size="large"
        layout="vertical"
        className="custom-form"
        wrapperCol={{
          flex: 1,
        }}
      >
        <DashboardActionBar {...{ actionBarOptions }} />

        {selectedForm?.error?.errors && (
          <Alert
            className="custom-alert"
            message="Error"
            showIcon
            description={[
              <ul>
                {Object.values(selectedForm.error.errors).map((itm, idx) => {
                  return <li key={idx}>{itm}</li>;
                })}
              </ul>,
            ]}
            type="error"
            closable
          />
        )}
        <Spin spinning={loading} delay={500}>
          <div className="common-module bg-white mt-3">
            <Row gutter={{ xs: 8, sm: 16, md: 24 }}>
              <Col className="gutter-row" xs={24} sm={12} xl={8}>
                <Form.Item
                  label="Setting Category"
                  name="setting_category"
                  rules={[
                    {
                      required: true,
                      message: "Category is required",
                    },
                  ]}
                >
                  <Select>
                    {settingCategories.map((itm, idx) => (
                      <Option key={idx} value={itm.value}>
                        {itm.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col className="gutter-row" xs={24} sm={12} xl={8}>
                <Form.Item
                  label="Label"
                  name="setting_label"
                  rules={[
                    {
                      required: true,
                      message: "label is required!",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col className="gutter-row" xs={24} sm={12} xl={8}>
                <Form.Item
                  label="Key"
                  name="setting_key"
                  rules={[
                    {
                      required: true,
                      message: "Setting key is required",
                    },
                  ]}
                >
                  <Input />
                </Form.Item>
              </Col>

              <Col className="gutter-row" xs={24} sm={12} xl={8}>
                <Form.Item
                  label="Setting Data Type"
                  name="setting_data_type"
                  rules={[
                    {
                      required: true,
                      message: "Setting Data Type is required",
                    },
                  ]}
                >
                  <Select>
                    {settingDataType.map((itm, idx) => (
                      <Option key={idx} value={itm.value}>
                        {itm.label}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              <Col className="gutter-row" xs={24} sm={12} xl={8}>
                <div className="switch-group">
                  <Form.Item label="Status" valuePropName="checked" name="setting_status">
                    <Switch checkedChildren="Active" unCheckedChildren="Not Active" />
                  </Form.Item>
                  <Form.Item label="Protection" valuePropName="checked" name="setting_protection">
                    <Switch checkedChildren="Protected" unCheckedChildren="Not Protected" />
                  </Form.Item>
                </div>
              </Col>
              <Col className="gutter-row" xs={24} sm={24} xl={24}>
                <Form.Item label="Help Text" name="setting_description">
                  <TextArea />
                </Form.Item>
              </Col>
            </Row>
          </div>
        </Spin>
      </Form>
    </>
  );
};

export default SettingForm;
