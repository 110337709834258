import {
  Alert,
  Button,
  Col,
  Collapse,
  Form,
  Input,
  InputNumber,
  Popconfirm,
  Row,
  Spin,
} from "antd";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import slugify from "react-slugify";
import { BASE_URL } from "../../lib/urls";
import TextEditor from "../Core/ReactTinymce/ReactTinymce";
import MediaManager from "../MediaManager";
import SpeedoMeter from "../SpeedoMeter";

import {
  useCreateItineraryMutation,
  useGetItineraryByPackageIdQuery,
  useUpdateItineraryInfoMutation,
} from "../../redux/slice/itinerary/itineraryApiSlice";
import {
  DATA_TEMPLATE,
  DEFAULT_SELECTED_DAY,
  DEFAULT_SELECTED_STOP,
} from "./MapResolution/ItineraryFormNewSupportComponents";
import MapDataForm from "./MapResolution/MapDataForm";

import { PlusOutlined } from "@ant-design/icons";
import useHandleCreateMessage from "../../hooks/useHandleCreateMessage";
import useHandleUpdateMessage from "../../hooks/useHandleUpdateMessage";
import { base64ToBlob } from "../../utils/base64ToBlob";
import { cleanFormValues } from "../../utils/cleanValues";

const { Panel } = Collapse;

const ItineraryForm = ({ isUpdateForm, isAddForm, package_id }) => {
  const navigate = useNavigate();
  let params = useParams();

  const [
    createEntity,
    {
      data: createdItineraryData,
      isLoading: creatingItinerary,
      isError,
      isSuccess: itineraryCreated,
    },
  ] = useCreateItineraryMutation("createItinerary");

  const [
    updateEntityInfo,
    {
      data: updatedItinerary,
      isLoading: updating,
      isError: updateItineraryError,
      error,
      isSuccess: itineraryUpdated,
    },
  ] = useUpdateItineraryInfoMutation("updateItinerary");
  const [isUpdateItinerary, setIsUpdateItinerary] = useState(isUpdateForm);

  const {
    data: itinerarys,
    isLoading: loadingById,
    isError: isGetError,
    isSuccess: successById,
  } = useGetItineraryByPackageIdQuery(package_id, { skip: !isUpdateItinerary });

  let data = {
    data: undefined,
    loading: updating || loadingById || updating,
    error: isGetError || updateItineraryError,
    dataById: successById && itinerarys,
  };

  const selectedForm = {
    data: undefined,
    loading: creatingItinerary || loadingById || updating,
    error: error?.data?.errors,
    loadedRecently: false,
    dataById: undefined,
  };

  const [description, setDescription] = useState(isUpdateItinerary && data.dataById?.description);
  const [formFields, setFormFields] = useState({
    [DEFAULT_SELECTED_DAY]: { [DEFAULT_SELECTED_STOP]: DATA_TEMPLATE },
  });
  const [selectedItems, setSelectedItems] = useState({});
  const [hasMap, setHasMap] = useState(false);
  const [itineraryThumb, setItineraryThumb] = useState(null);

  const [form] = Form.useForm();
  const { setFieldsValue } = form;

  const loading = selectedForm.loading;

  function transformDataForMap(itineraries) {
    return itineraries?.reduce((acc, itinerary) => {
      const day = itinerary.itinerary_day;
      if (!acc[day]) {
        acc[day] = {};
      }
      const stopNumber = Object.keys(acc[day]).length + 1;
      acc[day][stopNumber] = { ...itinerary };
      return acc;
    }, {});
  }

  const userAction = (e, action) => {
    e.preventDefault();
    form.validateFields().then((values) => {
      const cleanedValues = cleanFormValues(values);
      if (action === "add") {
        createEntity({
          ...cleanedValues,
          // status: cleanedValues.status ? 1 : 0,
          status: 1,
          ...(itineraryThumb && {
            itinerary_map: base64ToBlob(itineraryThumb),
          }),
          itineraries: hasMap ? formFields : transformDataForMap(cleanedValues.itineraries),
          package_id: params.id,
        })
          .unwrap()
          .then(() => {
            setIsUpdateItinerary(true);
          })
          .catch();
      } else if (action === "addandexit") {
        createEntity({
          ...cleanedValues,
          status: 1,
          ...(itineraryThumb && {
            itinerary_map: base64ToBlob(itineraryThumb),
          }),
          itineraries: hasMap ? formFields : transformDataForMap(cleanedValues.itineraries),
          package_id: params.id,
        })
          .unwrap()
          .then(() => navigate("/itineraries"))
          .catch();
      } else if (action === "update") {
        updateEntityInfo({
          formData: {
            ...cleanedValues,
            ...(itineraryThumb && {
              itinerary_map: base64ToBlob(itineraryThumb),
            }),
            itineraries: hasMap ? formFields : transformDataForMap(cleanedValues.itineraries),
            package_id: params.id,
          },
          id: data.dataById?.id,
        });
      }
    });
  };

  const addNewEntry = (e) => {
    userAction(e, "add");
  };

  const addNewEntryAndExistToList = (e) => {
    userAction(e, "addandexit");
  };

  const updateExistingEntry = (e) => {
    userAction(e, "update");
  };

  const clearForm = () => {
    form.resetFields();
    setDescription("");
  };
  if (isUpdateItinerary) {
    const itinerariesDetails = data.dataById?.details?.map((e) => {
      return {
        ...e,
        day: e.itinerary_day,
        description: e.itinerary_description,
        title: e.itinerary_title,
        facts: e.facts,
        accommodation: e.accommodation,
        distance: e.distance,
        duration: e.duration,
        destination: e.destination,
        travel_mode: e.travel_mode,
        meals: e.meals,
        origin: e.origin,
      };
    });

    setFieldsValue({
      itinerary_title: data.dataById?.itinerary_title,
      url_title: data.dataById?.urlinfo?.url_title,
      slug: data.dataById?.urlinfo?.url_slug,
      package_id: data.dataById?.package_id,
      status: data.dataById?.status === 1 ? true : false,
      meta_title: data.dataById?.meta?.meta_title,
      meta_keywords: data.dataById?.meta?.meta_keywords,
      meta_description: data.dataById?.meta?.meta_description,
      itineraries: itinerariesDetails,
    });
  }

  const actionBarOptions = {
    titleToAdd: "Add Itinerary",
    titleToUpdate: "Update Itinerary",
    taskCompleteMessageDisplayTime: 2000,
    addButtonLabel: "Save & Add New",
    updateButtonLabel: "Update Itinerary",
    taskCompleteAddMessage: "Itinerary is added!",
    taskCompleteUpdateMessage: "Itinerary is updated!",
    discardLink: "itineraries",
    addNewEntry,
    updateExistingEntry,
    loading,
    isAddForm,
    selectedForm,
    clearForm,
    addNewEntryAndExistToList,
  };

  const handleSelectItems = (itineraryId, items) => {
    setSelectedItems((prevState) => ({
      ...prevState,
      [itineraryId]: items,
    }));
  };

  const handleDeleteMedia = (itineraryId, itemId) => {
    setSelectedItems((prevState) => {
      const { [itineraryId]: items, ...rest } = prevState;
      const remainingItems = items.filter((item) => item.id !== itemId);

      return remainingItems.length ? { ...rest, [itineraryId]: remainingItems } : rest;
    });
  };

  useHandleCreateMessage(isError, itineraryCreated, "Itinerary");

  useHandleUpdateMessage(updateItineraryError, itineraryUpdated, "Itinerary");

  return (
    <>
      <Form
        form={form}
        size="large"
        layout="vertical"
        className="custom-form"
        wrapperCol={{
          flex: 1,
        }}
      >
        {selectedForm?.error?.errors && (
          <Alert
            className="custom-alert"
            message="Error"
            showIcon
            description={[
              <ul>
                {Object.values(selectedForm?.error?.errors).map((itm, idx) => {
                  return <li key={idx}>{itm}</li>;
                })}
              </ul>,
            ]}
            type="error"
            closable
          />
        )}

        <Spin spinning={loading} delay={500}>
          <div className="inner-action-bar header">
            <div className="left-content">
              <h2>Itinerary</h2>
              {isUpdateItinerary ? (
                <SpeedoMeter score={20} totalScore={20} />
              ) : (
                <SpeedoMeter score={5} totalScore={20} />
              )}
            </div>
            <div className="right-content">
              <div className="btn-group">
                <button
                  disabled={actionBarOptions.loading}
                  htmlType="submit"
                  className="btn btn-primary"
                  onClick={
                    isUpdateItinerary
                      ? actionBarOptions.updateExistingEntry
                      : actionBarOptions.addNewEntry
                  }
                  id="add_new"
                >
                  <i className="bi bi-save2"></i>
                  {isUpdateItinerary ? "Save" : "Create New"}
                </button>
              </div>
            </div>
          </div>

          <div className="module-container">
            <div className="common-module mt-3">
              <Row
                gutter={{ xs: 8, sm: 16, md: 24 }}
                // hidden={isUpdateItinerary}
              >
                <Col className="gutter-row" xs={24} sm={12} xl={8}>
                  <Form.Item
                    label="Title"
                    name="itinerary_title"
                    rules={[
                      {
                        required: true,
                        message: "Itinerary title is required",
                      },
                      { min: 5, max: 255, message: "Invalid Itinerary title" },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" xs={24} sm={12} xl={8}>
                  <Form.Item
                    label="Url Title"
                    name="url_title"
                    rules={[
                      {
                        required: true,
                        message: "Url title is required",
                      },
                      { min: 5, max: 255, message: "Invalid package title" },
                    ]}
                  >
                    <Input
                      onChange={(e) => form.setFieldsValue({ slug: slugify(e.target.value) })}
                    />
                  </Form.Item>
                </Col>
                <Col className="gutter-row" xs={24} sm={12} xl={8}>
                  <Form.Item
                    label={
                      <span className="ant-form-item-optional">Auto Generate from Url Title</span>
                    }
                    name="slug"
                    title="Auto Generate from Url Title"
                    rules={[
                      {
                        required: true,
                        message: "Slug is required",
                      },
                    ]}
                  >
                    <Input readOnly />
                  </Form.Item>
                </Col>
              </Row>
              {hasMap ? (
                <MapDataForm
                  {...{
                    itineraryThumb,
                    setItineraryThumb,
                    formFields,
                    setFormFields,
                    isUpdateItinerary,
                    ...{
                      itinerariesDetails: isUpdateItinerary && itinerarys ? itinerarys.details : [],
                    },
                  }}
                />
              ) : (
                <div className="itineraries itinerary-form">
                  {package_id !== undefined && (
                    <Form.List name="itineraries">
                      {(fields, { add, remove }) => (
                        <>
                          {fields.map((field, index) => {
                            return (
                              <Collapse
                                bordered={false}
                                defaultActiveKey={["0"]}
                                key={index}
                                expandIcon={({ isActive }) =>
                                  isActive ? (
                                    <i className="bi bi-dash"></i>
                                  ) : (
                                    <i className="bi-plus"></i>
                                  )
                                }
                              >
                                <Panel
                                  collapsible="icon"
                                  header={
                                    <h6 className="form-label">
                                      Day (
                                      {form.getFieldValue([
                                        "itineraries",
                                        field.name,
                                        "itinerary_day",
                                      ])}
                                      )
                                    </h6>
                                  }
                                  key={index}
                                  extra={
                                    <>
                                      {index >= 1 && (
                                        <>
                                          <Popconfirm
                                            placement="topLeft"
                                            title="Are you sure you want to remove this?"
                                            description={`Delete Itinerary for day ${form.getFieldValue(
                                              ["itineraries", field.name, "itinerary_day"]
                                            )}}`}
                                            okText="Yes"
                                            cancelText="No"
                                            onCancel={() => {}}
                                            onConfirm={() => remove(field.name)}
                                          >
                                            <button className="btn btn-delete">
                                              <i className="bi-trash"></i>
                                            </button>
                                          </Popconfirm>
                                          <Button
                                            onClick={() => {
                                              add("The head item", index + 1);
                                            }}
                                            size="small"
                                          >
                                            add day
                                          </Button>
                                        </>
                                      )}
                                    </>
                                  }
                                >
                                  <div className="common-module bg-white">
                                    <Row gutter={{ xs: 8, sm: 10, md: 10 }}>
                                      <Col className="gutter-row" xs={24} sm={4} xl={3}>
                                        <Form.Item
                                          {...field}
                                          name={[field.name, "itinerary_day"]}
                                          //initialValue={index + 1}
                                          rules={[
                                            {
                                              required: true,
                                              message: "Field is required",
                                            },
                                          ]}
                                        >
                                          <Input placeholder="Day" />
                                        </Form.Item>
                                      </Col>
                                      <Col className="gutter-row" xs={24} sm={20} xl={21}>
                                        <Form.Item
                                          {...field}
                                          name={[field.name, "itinerary_title"]}
                                          rules={[
                                            {
                                              required: true,
                                              message: "Title is required",
                                            },
                                          ]}
                                        >
                                          <Input placeholder="Itinerary title (Outline)*" />
                                        </Form.Item>
                                        <Form.Item
                                          {...field}
                                          name={[field.name, "itinerary_description"]}
                                        >
                                          <TextEditor
                                            simpleTollbar
                                            placeholder="Itinerary description"
                                          />
                                        </Form.Item>
                                        {isUpdateItinerary && (
                                          <>
                                            <Form.Item
                                              hidden
                                              {...field}
                                              label="&nbsp;"
                                              initialValue={0}
                                              name={[field.name, "id"]}
                                            >
                                              <InputNumber />
                                            </Form.Item>
                                          </>
                                        )}
                                        <Row gutter={{ xs: 8, sm: 10, md: 10 }}>
                                          <Col className="gutter-row" xs={24} sm={12} xl={8}>
                                            <Form.Item
                                              label="Accommodation"
                                              {...field}
                                              name={[field.name, "accommodation"]}
                                            >
                                              <Input placeholder="accommodation.." />
                                            </Form.Item>
                                          </Col>
                                          <Col className="gutter-row" xs={24} sm={12} xl={8}>
                                            <Form.Item
                                              label="Meals"
                                              {...field}
                                              name={[field.name, "meals"]}
                                            >
                                              <Input placeholder="meals.." />
                                            </Form.Item>
                                          </Col>
                                          <Col className="gutter-row" xs={24} sm={12} xl={8}>
                                            <Form.Item
                                              label="Transportation"
                                              {...field}
                                              name={[field.name, "travel_mode"]}
                                            >
                                              <Input placeholder="travel_mode.." />
                                            </Form.Item>
                                          </Col>
                                          <Col className="gutter-row" xs={24} sm={12} xl={8}>
                                            <Form.Item
                                              label="Distance"
                                              {...field}
                                              name={[field.name, "distance"]}
                                            >
                                              <Input placeholder="distance.." />
                                            </Form.Item>
                                          </Col>
                                          <Col className="gutter-row" xs={24} sm={12} xl={8}>
                                            <Form.Item
                                              label="Duration"
                                              {...field}
                                              name={[field.name, "duration"]}
                                            >
                                              <Input placeholder="duration.." />
                                            </Form.Item>
                                          </Col>
                                          <Col className="gutter-row" xs={24}>
                                            <Form.Item
                                              {...field}
                                              name={[field.name, "gallery"]}
                                              className="mb-0"
                                              label="Gallery"
                                            >
                                              <div className="media-display-slot">
                                                <ul>
                                                  {selectedItems[
                                                    form.getFieldValue([
                                                      "itineraries",
                                                      field.name,
                                                      "id",
                                                    ])
                                                  ]?.map((item, idx) => {
                                                    return (
                                                      <li key={idx}>
                                                        <figure>
                                                          <div className="image-slot">
                                                            <img
                                                              key={idx}
                                                              src={BASE_URL + item.full_path}
                                                              alt={`Gallery item ${idx + 1}`}
                                                            />
                                                          </div>

                                                          <figcaption>
                                                            <button
                                                              type="button"
                                                              className="btn-trash"
                                                              onClick={() =>
                                                                handleDeleteMedia(
                                                                  form.getFieldValue([
                                                                    "itineraries",
                                                                    field.name,
                                                                    "id",
                                                                  ]),
                                                                  item.id
                                                                )
                                                              }
                                                            >
                                                              <i className="bi-trash"></i>
                                                            </button>
                                                          </figcaption>
                                                        </figure>
                                                      </li>
                                                    );
                                                  })}

                                                  {form
                                                    .getFieldValue([
                                                      "itineraries",
                                                      field.name,
                                                      "gallery",
                                                    ])
                                                    ?.map((item, idx) => (
                                                      <li key={idx}>
                                                        <figure>
                                                          <div className="image-slot">
                                                            <img
                                                              key={idx}
                                                              src={BASE_URL + item?.media.full_path}
                                                              alt={`Gallery item ${idx + 1}`}
                                                            />
                                                          </div>
                                                          <figcaption>
                                                            <button
                                                              type="button"
                                                              className="btn-trash"
                                                              onClick={() =>
                                                                handleDeleteMedia(
                                                                  form.getFieldValue([
                                                                    "itineraries",
                                                                    field.name,
                                                                    "id",
                                                                  ]),
                                                                  item.id
                                                                )
                                                              }
                                                            >
                                                              <i className="bi-trash"></i>
                                                            </button>
                                                          </figcaption>
                                                        </figure>
                                                      </li>
                                                    ))}
                                                  <li className="btn-media-add">
                                                    <MediaManager
                                                      mediaKeyType="Itinerary Gallery"
                                                      isMultiple
                                                      onSelectItems={(items) =>
                                                        handleSelectItems(
                                                          form.getFieldValue([
                                                            "itineraries",
                                                            field.name,
                                                            "id",
                                                          ]),
                                                          items
                                                        )
                                                      }
                                                    />
                                                  </li>
                                                </ul>
                                              </div>
                                            </Form.Item>
                                          </Col>
                                        </Row>
                                      </Col>
                                    </Row>
                                  </div>
                                </Panel>
                              </Collapse>
                            );
                          })}

                          {fields.length < 2 && (
                            <Form.Item>
                              <button
                                className="btn btn-secondary btn-md mt-4"
                                type="button"
                                onClick={() => add()}
                              >
                                <PlusOutlined /> Add New Itinerary (Day)
                              </button>
                            </Form.Item>
                          )}
                        </>
                      )}
                    </Form.List>
                  )}
                </div>
              )}
            </div>
          </div>
        </Spin>
      </Form>
    </>
  );
};

export default ItineraryForm;
