import { Alert, Col, Form, Input, message, Row, Select, Spin, Switch, TreeSelect } from "antd";
import clean from "lodash-clean";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useGetMenuListQuery, useGetMenuOptionsQuery } from "../../redux/slice/menu/menuApiSlice";
import { useGetMenuTypeListQuery } from "../../redux/slice/menu/menuTypeApiSlice";
import { cleanFormValues } from "../../utils/cleanValues";
import DashboardActionBar from "../DashboardActionBar";

const { Option, OptGroup } = Select;
const { TreeNode } = TreeSelect;

const MenuForm = ({
  selectedForm,
  createEntity,
  updateEntityInfo,
  isAddForm,
  isUpdateForm,
  params,
  data,
}) => {
  const navigate = useNavigate();
  const { data: menuList, isLoading: menuListLoading } = useGetMenuListQuery();
  const { data: menuTypeList, isLoading: menuTypeListLoading } = useGetMenuTypeListQuery();

  const { data: menuOptionsList, isLoading: menuOptionsListLoading } = useGetMenuOptionsQuery();
  const [form] = Form.useForm();
  const { setFieldsValue } = form;

  const loading =
    selectedForm.loading || menuTypeListLoading || menuListLoading || menuOptionsListLoading;

  const [treeLine, setTreeLine] = useState(true);
  const [showLeafIcon, setShowLeafIcon] = useState(false);
  const myData = clean(menuOptionsList);
  const destination = myData?.destination;
  const team = myData?.team;
  const page = myData?.page;
  const blog = myData?.blog;

  const menuTypeAction = (e, action) => {
    e.preventDefault();
    form.validateFields().then((values) => {
      const cleanedValues = cleanFormValues(values);

      if (action === "add") {
        createEntity({
          ...cleanedValues,
          is_external: cleanedValues.is_external ? 1 : 0,
        });
      } else if (action === "addandexit") {
        createEntity({
          ...cleanedValues,
          is_external: cleanedValues.is_external ? 1 : 0,
        })
          .unwrap()
          .then(() => navigate("/menus"))
          .catch((e) => console.log("error creating menu", e));
      } else if (action === "update" && params.id) {
        updateEntityInfo({
          formData: {
            ...cleanedValues,
            is_external: cleanedValues.is_external ? 1 : 0,
          },
          id: params.id,
        })
          .unwrap()
          .then((data) => {
            message.success("Menu updated successfully");
          })
          .catch((error) => console.log("error creating menu", error));
      }
    });
  };

  const addNewEntry = (e) => {
    menuTypeAction(e, "add");
  };

  const addNewEntryAndExistToList = (e) => {
    menuTypeAction(e, "addandexit");
  };

  const updateExistingEntry = (e) => {
    menuTypeAction(e, "update");
  };

  const clearForm = () => {
    form.resetFields();
  };

  const actionBarOptions = {
    titleToAdd: "Add Menu",
    titleToUpdate: "Update Menu",
    taskCompleteMessageDisplayTime: 2000,
    addButtonLabel: "Save & Add New",
    updateButtonLabel: "Update Menu",
    taskCompleteAddMessage: "Menu is added!",
    taskCompleteUpdateMessage: "Menu is updated!",
    discardLink: "menus",
    addNewEntry,
    updateExistingEntry,
    loading,
    isAddForm,
    isUpdateForm,
    selectedForm,
    clearForm,
    addNewEntryAndExistToList,
  };
  const menuTypeListOptions = menuTypeList?.map((itm) => {
    return {
      label: itm.type_title,
      value: itm.id.toString(),
    };
  });

  const menuListOptions = clean(menuList)?.map((itm) => {
    return {
      value: itm.id,
      label: itm.item_title,
      ...(itm.menu?.length !== 0 && {
        menu: itm.menu?.map((a) => {
          return {
            value: a.id,
            label: a.item_title,
            ...(a.menu?.length !== 0 && {
              menu: a.menu?.map((b) => {
                return {
                  value: b.id,
                  label: b.item_title,
                };
              }),
            }),
          };
        }),
      }),
    };
  });

  if (isUpdateForm) {
    setFieldsValue({
      type_id: data.dataById?.type_id?.toString(),
      parent_id: data.dataById?.parent_id,
      is_external: data.dataById?.is_external === 1 ? true : false,
      item_title: data.dataById?.item_title,
      external_link: data.dataById?.external_link,
      url_segment: data.dataById?.url_segment,
    });
  }

  return (
    <Form
      form={form}
      size="large"
      layout="vertical"
      className="custom-form"
      wrapperCol={{
        flex: 1,
      }}
    >
      <DashboardActionBar {...{ actionBarOptions }} />

      {selectedForm?.error?.errors && (
        <Alert
          className="custom-alert"
          message="Error"
          showIcon
          description={[
            <ul>
              {Object.values(selectedForm?.error).map((itm, idx) => {
                return <li key={idx}>{itm}</li>;
              })}
            </ul>,
          ]}
          type="error"
          closable
        />
      )}

      <Spin spinning={loading} delay={500}>
        <div className="common-module">
          <Row gutter={{ xs: 8, sm: 16, md: 24 }}>
            <Col className="gutter-row" xs={24} sm={12} xl={8}>
              <Form.Item
                label="Title"
                name="item_title"
                rules={[
                  {
                    required: true,
                    message: "Title is required",
                  },
                  { min: 2, max: 255, message: "Invalid Title" },
                ]}
              >
                <Input />
              </Form.Item>
            </Col>
            <Col className="gutter-row" xs={24} sm={12} xl={8}>
              <Form.Item
                label="Type"
                name="type_id"
                rules={[
                  {
                    required: true,
                    message: "Type is required",
                  },
                ]}
              >
                <Select>
                  {menuTypeListOptions?.map((itm, idx) => {
                    return (
                      <Option key={idx} value={itm.value}>
                        {itm.label}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Col>

            <Col className="gutter-row" xs={24} sm={12} xl={8}>
              <Form.Item
                label="Parent Menu"
                name="parent_id"
                rules={[
                  {
                    required: true,
                    message: "Parent is required",
                  },
                ]}
              >
                <TreeSelect
                  showSearch
                  style={{ width: "100%" }}
                  dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                  placeholder="Please select"
                  allowClear
                  treeDefaultExpandAll={true}
                >
                  <TreeNode value="0" title="No parent" />
                  {menuListOptions?.map((parent) => {
                    const { value, label, children } = parent;
                    return (
                      <TreeNode key={value} value={value} title={label}>
                        {children?.length !== "0" &&
                          children?.map((child) => {
                            const { value, label, children } = child;
                            return (
                              <TreeNode key={value} value={value} title={label}>
                                {children?.length !== "0" &&
                                  children?.map((gChild) => {
                                    const { value, label } = gChild;
                                    return <TreeNode key={value} value={value} title={label} />;
                                  })}
                              </TreeNode>
                            );
                          })}
                      </TreeNode>
                    );
                  })}
                </TreeSelect>
              </Form.Item>
            </Col>

            <Col className="gutter-row" xs={24} sm={12} xl={8}>
              <Form.Item
                label="Url Segment"
                name="url_segment"
                rules={[
                  {
                    required: true,
                    message: "Please select url segment",
                  },
                ]}
              >
                <TreeSelect
                  showSearch
                  style={{ width: "100%" }}
                  dropdownStyle={{ maxHeight: 400, overflow: "auto" }}
                  placeholder="Please select"
                  allowClear
                  treeLine={treeLine && { showLeafIcon }}
                >
                  {destination?.map((parent) => {
                    const { slug, title, children } = parent;
                    return (
                      <TreeNode key={slug} value={slug} title={title}>
                        {children?.length !== "0" &&
                          children?.map((child) => {
                            const { slug, title, children } = child;
                            return (
                              <TreeNode key={slug} value={slug} title={title}>
                                {children?.length !== "0" &&
                                  children?.map((gChild) => {
                                    const { slug, title } = gChild;
                                    return <TreeNode key={slug} value={slug} title={title} />;
                                  })}
                              </TreeNode>
                            );
                          })}
                      </TreeNode>
                    );
                  })}
                  {page?.map((parent) => {
                    const { slug, title, children } = parent;
                    return (
                      <TreeNode key={slug} value={slug} title={title}>
                        {children?.length !== "0" &&
                          children?.map((child) => {
                            const { slug, title, children } = child;
                            return (
                              <TreeNode key={slug} value={slug} title={title}>
                                {children?.length !== "0" &&
                                  children?.map((gChild) => {
                                    const { slug, title } = gChild;
                                    return <TreeNode key={slug} value={slug} title={title} />;
                                  })}
                              </TreeNode>
                            );
                          })}
                      </TreeNode>
                    );
                  })}
                  {blog?.map((parent) => {
                    const { slug, title, children } = parent;
                    return (
                      <TreeNode key={slug} value={slug} title={title}>
                        {children?.length !== "0" &&
                          children?.map((child) => {
                            const { slug, title, children } = child;
                            return (
                              <TreeNode key={slug} value={slug} title={title}>
                                {children?.length !== "0" &&
                                  children?.map((gChild) => {
                                    const { slug, title } = gChild;
                                    return <TreeNode key={slug} value={slug} title={title} />;
                                  })}
                              </TreeNode>
                            );
                          })}
                      </TreeNode>
                    );
                  })}
                  {team?.map((parent) => {
                    const { slug, title, children } = parent;
                    return (
                      <TreeNode key={slug} value={slug} title={title}>
                        {children?.length !== "0" &&
                          children?.map((child) => {
                            const { slug, title, children } = child;
                            return (
                              <TreeNode key={slug} value={slug} title={title}>
                                {children?.length !== "0" &&
                                  children?.map((gChild) => {
                                    const { slug, title } = gChild;
                                    return <TreeNode key={slug} value={slug} title={title} />;
                                  })}
                              </TreeNode>
                            );
                          })}
                      </TreeNode>
                    );
                  })}
                </TreeSelect>

                {/* <Select >
                {clean(menuOptionsList.options?.data)?.map((itm, idx) => {
                  return(
                    <OptGroup label={itm} key={idx}></OptGroup>
                  )

                })}

              </Select> */}
              </Form.Item>
            </Col>

            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.is_external !== currentValues.is_external
              }
            >
              {({ getFieldValue }) =>
                getFieldValue("is_external") === true ? (
                  <Col className="gutter-row" xs={24} sm={12} xl={8}>
                    <Form.Item
                      label="External link"
                      name="external_link"
                      rules={[
                        {
                          required: true,
                          message: "Please input your url",
                        },
                        { type: "url", warningOnly: true },
                      ]}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                ) : null
              }
            </Form.Item>

            <Col className="gutter-row" xs={24} sm={12} xl={8}>
              <div className="switch-group">
                <Form.Item label="Is external link?" valuePropName="checked" name="is_external">
                  <Switch checkedChildren="Yes" unCheckedChildren="No" />
                </Form.Item>
              </div>
            </Col>
          </Row>
        </div>
      </Spin>
    </Form>
  );
};
export default MenuForm;
